
<template>
  <div class="content" v-loading="loading">
    <b-container fluid>
      <b-row class="drs">
        <b-col cols="12">
          <h5 class="name mgb20 tc">
            <!-- <span class="ft12" style="color:#999;" v-if="catid!=null">軟裝供應商 /</span> -->
            <em v-if="cl==3">{{$t('supplierfilter.supplietype2')}}</em>
            <em v-else-if="cl==2">{{$t('supplierfilter.supplietype3')}}</em>
            <em v-else-if="cl==1">{{$t('supplierfilter.supplietype4')}}</em>
            <em v-else-if="cl==5">{{$t('supplierfilter.supplietype5')}}</em>
            <em v-else>{{$t('supplierfilter.supplietype1')}}</em>
          </h5>
        </b-col>
        <b-col cols="12">
          <div class="drs-list">
            <ul>
              <li
                class="thumb"
                v-for="(item,index) in resultList"
                :key="index"
                @click="goPage(item.id)"
              >
                <div class="img-box flex">

                    <img :src="url + 'misc/tiles/specialist-lighting.jpg'" v-if="item.id == 2">

                    <img :src="url + 'misc/tiles/specialist-mep.jpg'" v-else-if="item.id == 3">

                    <img :src="url + 'misc/tiles/specialist-landscape-design.jpg'"  v-else-if="item.id == 4">

                    <img :src="url + 'misc/tiles/specialist-quantity-surveying.jpg'"  v-else-if="item.id == 5">

                    <img :src="url + 'misc/tiles/specialist-signage.jpg'"  v-else-if="item.id == 6">

                    <img :src="url + 'misc/tiles/specialist-sustainable.jpg'" v-else-if="item.id == 7">

                    <img :src="url + 'misc/tiles/specialist-security.jpg'" v-else-if="item.id == 8">

                    <img :src="url + 'misc/tiles/specialist-acoustic.jpg'" v-else-if="item.id == 10">

                    <img :src="url + 'misc/tiles/cat-img-36.jpg'" v-else-if="item.id == 14">

                    <img :src="url + 'misc/tiles/designres-design-guide-drawing-ref.jpg'" v-else-if="item.id == 15">

                    <img :src="url + 'misc/tiles/designres-design-guide-detail-drawing-ref.jpg'" v-else-if="item.id == 16">

                    <img :src="url + 'misc/tiles/specialist-m&e.jpg'" v-else-if="item.id == 17">

                    <img :src="url + 'misc/tiles/specialist-vr.jpg'" v-else-if="item.id == 18">

                    <img :src="url + 'misc/tiles/specialist-bim.jpg'" v-else-if="item.id == 19">

                    <img :src="url + 'misc/tiles/specialist-photographer.jpg'" v-else-if="item.id == 20">

                    <img :src="url + 'misc/tiles/specialist-security.jpg'" v-else-if="item.id ==21 ">

                    <img :src="url + 'misc/tiles/specialist-smart-home.jpg'" v-else-if="item.id == 22">

                    <img :src="url + 'misc/tiles/specialist-security-doors.jpg'" v-else-if="item.id == 23">

                    <img :src="url + 'misc/tiles/specialist-wine-cellars.jpg'" v-else-if="item.id == 24">

                    <img :src="url + 'misc/tiles/specialist-water.jpg'" v-else-if="item.id == 25">

                    <img :src="url + 'misc/tiles/specialist-av.jpg'" v-else-if="item.id == 26">

                    <img :src="url + 'misc/tiles/specialist-air.jpg'" v-else-if="item.id == 27">

                    <img :src="url + 'misc/tiles/specialist-kitchen.jpg'" v-else-if="item.id == 28">

                    <img :src="url + 'misc/tiles/specialist-wellness-sys.jpg'" v-else-if="item.id == 29">

                    <img :src="url + 'misc/tiles/cat-img-42.jpg'" v-else-if="item.id == 31">

                    <img :src="url + 'misc/tiles/specialist-gym.jpg'" v-else-if="item.id == 35">

                    <img :src="url + 'misc/tiles/specialist-drafting-printing.jpg'" v-else-if="item.id == 36">

                    <img :src="url + 'misc/tiles/specialist-pro-photographer.jpg'" v-else-if="item.id == 37">

                    <img :src="url + 'misc/tiles/specialist-maintenance-gz.jpg'" v-else-if="item.id == 38">

                    <img :src="url + 'misc/tiles/specialist-maintenance-sh.jpg'" v-else-if="item.id == 39">

                    <img :src="url + 'misc/tiles/specialist-maintenance-bj.jpg'" v-else-if="item.id == 40">

                    <img :src="url + 'misc/tiles/specialist-maintenance-hk.jpg'" v-else-if="item.id == 41">

                    <img :src="url + 'misc/tiles/specialist-architecture.jpg'" v-else-if="item.id == 42">

                    <img :src="url + 'misc/tiles/specialist-it.jpg'" v-else-if="item.id == 44">

                    <img :src="url + 'misc/tiles/specialist-parking.jpg'" v-else-if="item.id == 45">

                    <img :src="url + 'misc/tiles/specialist-digital-workspace.jpg'" v-else-if="item.id == 46">

                    <img :src="url + 'misc/tiles/specialist-smart-hospitality.jpg'" v-else-if="item.id == 47">

                    <img :src="url + 'misc/tiles/specialist-strong-room.jpg'" v-else-if="item.id == 48">

                    <img :src="url + 'misc/tiles/specialist-vr-interaction.jpg'" v-else-if="item.id == 49">

                    <img :src="url + 'misc/tiles/specialist-gym.jpg'" v-else-if="item.id == 50">

                    <img :src="url + 'misc/tiles/specialist-exhibition-systems.jpg'" v-else-if="item.id == 51">

                    <img :src="url + 'misc/tiles/specialist-2nd-rank-lighting.jpg'" v-else-if="item.id == 52">

                    <img :src="url + 'misc/tiles/designres-tm002.jpg'" v-else>

                </div>
                <span>{{item.name}}</span>
              </li>
              <li class="thumb forJustify"></li>
              <li class="thumb forJustify"></li>
              <li class="thumb forJustify"></li>
              <li class="thumb forJustify"></li>
              <li class="thumb forJustify"></li>
            </ul>
          </div>
        </b-col>
        <!-- <b-col cols="12">
          <div class="renderer-list">
            <ul>
              <li class="thumb">
                  <div class="render-top">
                    <div class="text flex-center">上海東大室內設計工程有限公司</div>
                    <div class="img-box">
                      <img src="http://res.steveleungdesign.com/renderer/17/49/abb559d47259fb26e889764fe13eb238.jpg/s300">
                    </div>
                  </div>
                  <div class="render-title">上海東大室內設計工程有限公司</div>
                  <div class="expertise">
                    <div>二次機電 / 施工圖 / 擴初招標圖</div>
                  </div>
                  <div class="score"><el-rate v-model="value1"></el-rate></div>
                  <div class="rating">
                    <div class="rate"><span class="">年度评分:</span><br/> 4</div>
                    <div class="rate"><span class="">性价比:</span><br/><el-rate v-model="value1"></el-rate></div>
                  </div>
              </li>
            </ul>
          </div>
        </b-col>-->
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { getCompanyType } from "@/api/supply";
export default {
  data() {
    return {
      cl: null,
      catName: "",
      resultList: [],
      loading:false,
      url: process.env.VUE_APP_IMG_URL
    };
  },
  created() {
    this.cl = this.$route.query.cl;
    this.getCompanyTypeList();
  },
  methods: {
    getCompanyTypeList() {
        this.loading = true;
      getCompanyType(this.cl).then(res => {
        this.loading = false;
        this.resultList = res.data;
      });
    },
    goPage(id) {
      this.$router.push(`/supplier-companylist?cl=${this.cl}&ct=${id}`);
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
  padding: 25px 0 40px;
  overflow-x: hidden;
  .name {
    padding: 20px 0px 20px;
  }
  .drs {
    max-width: 1530px;
    margin: 0 auto;
    text-align: left;
    .col-12 {
      padding: 0;
    }
    @media (max-width: 1559px) {
      .drs-list {
        text-align: justify;
        .thumb {
          padding: 7.5px;
        }
      }
    }

    @media (max-width: 1023px) {
      /*margin: 0 -15px;*/
      .drs-list {
        .thumb {
          width: 33.33%;
          span {
            padding: 15px 0 8px;
          }
        }
      }
    }
    @media (max-width: 767px) {
      /*margin: 0 -15px;*/
      .drs-list {
        width: auto;
        margin-bottom: 50px;
        .thumb {
          margin: 0 0 20px 0;
        }
      }
    }
    @media (max-width: 567px) {
      .drs-list {
        .thumb {
          width: 50%;
          font-size: 13px;
          span {
            font-size: 15px;
          }
        }
      }
    }
    @media (max-width: 375px) {
      .drs-list {
        .thumb {
          span {
            font-size: 13px;
          }
        }
      }
    }
  }
  .reders {
    max-width: 1530px;
    margin: 0 auto;
    text-align: left;
    @media (max-width: 1023px) {
      margin: 0 -15px;
      text-align: center;
    }
  }
}
.drs-list {
  margin: 0 auto;
  /* max-width: 1100px; */
  width: 100%;
  padding: 0;
  text-align: center;
  font-size: 0;
  margin-bottom: 50px;
  > ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .thumb {
    z-index: 0;
    display: inline-block;
    width: 20%;
    text-align: left;
    font-size: 15px;
    margin: 0px 0px 1% 0;
    padding: 15px;
    vertical-align: top;
    cursor: pointer;
    .img-box {
      position: relative;
      overflow: hidden;
      img {
        width: 100%;
        height: auto;
        transition: transform 1s;
        transition-timing-function: ease;
      }
    }
    span {
      display: block;
      font-size: 18px;
      padding: 20px 0 10px;
      text-align: center;
      letter-spacing: 1px;
      color: #555555;
      position: relative;
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }
  .forJustify {
    border: none;
    background: none;
    height: 1px;
    padding: 0px;
    margin-bottom: 0;
    /* margin: 0 2% 0 0; */
  }
}
.renderer-list {
  /* max-width: 1100px; */
  padding: 0;
  /* text-align: center; */
  text-align: justify;
  font-size: 0;
  margin: 0px 0 60px;
  vertical-align: top;
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      box-sizing: border-box;
      font-size: 14px;
      background-color: #ffffff;
      /*border: 1px solid #DDDDDD;*/
      /*border-radius: 3px;
      box-shadow: 1px 3px 1px rgba(0, 0, 0, 0.08);*/
      /*max-width:250px;*/
      margin: 0px 0px 50px;
      padding: 15px 15px 35px;
      width: 19%;
      text-align: center;
      vertical-align: top;
      display: inline-block;
      position: relative;
      @media (max-width: 1023px) {
        width: 33%;
        padding: 0 2% 35px;
      }
      @media (max-width: 767px) {
        width: 49%;
        padding: 0 2% 35px;
        margin: 0 0 30px;
      }
      .render-top {
        position: relative;
        padding: 0px;
        display: block;
        color: #555555;
        .text {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          vertical-align: middle;
          color: #ccc;
          padding: 10px;
        }
        .img-box {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          img {
            display: block;
            min-width: 100%;
            min-height: 100%;
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
            margin: auto 0;
            object-fit: cover;
          }
        }
      }
      .render-title {
        display: block;
        font-size: 15px;
        padding: 20px 0 2px;
        text-align: center;
        letter-spacing: 1px;
        color: #555555;
      }
      .expertise {
        margin: 0 0 15px;
        > div {
          font-size: 14px;
          color: #999999;
        }
      }
      .rating {
        display: flex;
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        padding: 0px 15px;
        color: #999999;
        font-size: 12px;
        .rate {
          flex: 1;
          &:last-child {
            border-left: 1px solid #eaeaea;
          }
        }
      }
    }
  }
}
</style>
