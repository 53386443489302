<template>
  <div>
    <div
      :id="viewerDivId"
      class="customViewer"
    ></div>
  </div>
</template>
<script setup>
import { toRef, ref, onBeforeMount, onMounted } from 'vue';
// import useAttachment from '@/views/common/hook/useAttachment';
const props = defineProps({
  dataRoot: {
    type: String,
    default: "root"
  },
  dataStr: {
    type: String,
    default: ""
  },
  dataName: {
    type: String,
    default: "Preview.pdf"
  },
  dataId: {
    type: Number,
    default: 0
  }
})
const inputDataRoot = toRef(props, "dataRoot");
const inputDataStr = toRef(props, "dataStr");
const inputDataName = toRef(props, 'dataName');
const inputDataId = toRef(props, "dataId");
const adobeDCViewer = ref(null);
const viewerDivId = "viewer-dc-view-" + inputDataRoot.value + "-" + inputDataId.value;

// const {
//     b64ToArrayBuffer,
// } = useAttachment();

onMounted(async ()=> {
  initAdobe();
})

const initViewer = () => {
  let locale = "zh-TW"; // better display than "en-US"
  adobeDCViewer.value = new AdobeDC.View({
    clientId: process.env.VUE_APP_PDF_CLIENTID,
    divId: viewerDivId,
    locale
  });
}

const loadPdf = () => {
  if (!adobeDCViewer.value) return ;
  // let outputData = b64ToArrayBuffer(inputDataStr.value);
  let outputData = inputDataStr.value;
  adobeDCViewer.value.previewFile({
      content:{
        location: {url: outputData}
      },
      metaData: {
        fileName: (inputDataName.value ?? "Preview") + ".pdf"
      }
    },
    {
      defaultViewMode: "FIT_WIDTH",
      showAnnotationTools: false,
      showDownloadPDF: false,
      showPrintPDF: false
    });
}

const initViewerWithContent = () => {
  initViewer();
  loadPdf();
}

const initAdobe = () => {
  if (!window?.AdobeDC) {
    // load and init. the adobe pdf viewer
    let script = document.createElement('script')
    script.setAttribute('src', 'https://acrobatservices.adobe.com/view-sdk/viewer.js')
    document.head.appendChild(script)
    document.addEventListener("adobe_dc_view_sdk.ready", initViewerWithContent);
  } else {
    initViewer();
    loadPdf();
  }
}
</script>

<style scoped>
div.customViewer  {
  height: 100%;
  width: 100%;
}
</style>
