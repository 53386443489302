export default {
  common: {
    login: '登錄',
    email:'電子郵件地址',
    password:'密碼',
    system:'設計資源系統',
    forgetPassword:'忘記密碼？',
    rember:'記住我 ',
	  adminCenter:'管理中心',
    Load: '正在加載中',
    loadmore: '載入更多',
    loadover:'(已加載全部數據)',
    headsearch:'搜索',
    userreview:'我的評分',
    changepassword:'更改密碼',
    loginout:'登出',
    language:'語言',
    noData:'沒有匹配的結果!',
    result_none: "沒有結果",
    inform: "我的查询清單",
    wishlist:'我的珍藏',
    metaname:"設計資源系統",
    lib_file: "檔案下載",
    all_cat: "全部分類",
    prodCategory:'全部產品分類',
    wishCategory:'全部珍藏分類',
    sent: "已發送",
    draft: "未發送",
    details: "詳情",
    confirm_to_del: "確認刪除？",
    confirm: "確認",
    cancel: "取消",
    copyright: "版權所有",
    action: "發送重置鏈接",
    message: "返回登錄頁",
    news: "新發佈",
    year:"年份",
    search_results: "搜尋結果",
    no_result: "沒有匹配的結果",
    product:"物料產品",
    my_review: "我的評分",
    to_be_rated: "待評分",
    rated: "已評分",
    supplier_rating: "供應商評分",
    rate: "評分",
    ratings_success: "評分成功！",
    ratings_mandatory: "相關字段必須填寫。",
    ratings_mandatory_rating:"評分字段是必須的。",
    select_region: "請選擇適用地區 / 項目地區",
    forHK: "香港",
    forCN: "中國內地",
    forOS: "海外",
    resetPassword:'重設密碼',
    new_products: "最新產品",
    recently_viewed: "最近檢視的產品",
    product_cat: "按產品分類",
    sortOption:[
      {
        value:'',
        text:'排序方式'
      },{
        value:'asc',
        text:'按升序'
      },{
        value:'desc',
        text:'按降序'
      },
    ],

  },
  productfilter:{
    product_tbc:"查詢(另議)",
    products: "產品",
    Highlight:'重點',
    Category:'分類',
    FitOut:'硬裝產品',
    Furnishings:'軟裝產品',
    filter_subcat: "副分類",
    Brand:'品牌',
    Series:'系列',
    Color:'顏色',
    MainMaterials:'主要材質',
    Library:'設計風格',
    retailprice:'參考零售價',
    costprice:'參考成本價',
    Size:'尺寸',
    Leadtime:'週期',
    Day:'日',
    Lastupdate:'最後更新',
    Produtype:'跨界合作產品',
    inc_ship_cost: "價格已含運費",
    inc_vat: "價格已含稅",
    contactinfo:'聯繫資料',
    contact_project_info: "項目資料",
    contact_product_info: "產品資料",
    contact_request: "要求提供",
    contact_mobile: "手提電話",
    contact_wechat: "微信號",
    Vendor:'聯繫廠家 / 供應商',
    Contactperson:'聯繫人',
    Telephone:'電話',
    Email:'電郵地址',
    projectNum:'選擇項目編號',
    Attachment:'附件',
    Toemail:'抄送郵件到',
    Myphone:'我的手機號',
    Wechat:'我的微信號',
    time:'回復/匯報時間',
    Addequiry:'添加到查詢訂單',
    Send:'發送',
    Cancel:'取消',
    project1:'包含項目名稱',
    project2:'包含客戶名稱',
    project3:'提供樣板',
    project4:'提供方案',
    msg:'列明需查詢產品例: 039 頁: LW47245B',
    checked:'全選',
    checkednum:'個被選中',
    Region:'適用地區',
    orderby:'排序方式',
    pageShow: '每頁顯示',
    pictureShows:'按類顯示',
    pictureOptions:[{
        value:null,
        text:'預設顯示'
      },{
        value:1,
        text:'產品目錄'
      },{
        value:0,
        text:'產品'
      }],
    regionption:[
      {
        value:'HK',
        text:'香港'
      },{
        value:'CN',
        text:'中國內地'
      },{
        value:'OS',
        text:'海外'
      }
    ],
    sortSelectOptions: [
      {
        text:'預設排序',
        val:'default'
      },{
        text:'最新',
        val:'newest'
      },{
        text:'價格從低到高',
        val:'priceasc'
      },{
        text:'價格從高至低',
        val:'pricedesc'
      },{
        text:'訪問次數從高至低',
        val:'pageview'
      },{
        text:'珍藏次數從高至低',
        val:'wishlist'
      }

    ],
    unit:'項',
    resultxt:'的搜索結果',
    moreDatail:'更多細節...',
    description:'描述/规格',
    brand_products: '跨界合作品牌產品',
    new_brand_products: '最新跨界合作品牌產品',
    own_products: '自家研發產品',
    strategic_products: '戰略合作品牌產品',
    recommended_products: "認可合作品牌產品",
    registered_products: "協議合作品牌產品",
    strategic_brands: "戰略合作品牌",
    contact_alert_select_project: "請先選擇一個項目！",
    recommended_brands: "認可品牌",
    registered_brands: "協議合作品牌",
    remark: "備註",
    fit_out_products: "硬装产品",
    decorative: "裝飾品",
    specialized_companies: "供應商",
    design_styles: "設計風格",
    Confirm:'確定',
    NotCategory:'不選擇分類',
    CreateCategory:'新建分類',
    DefaultCategory:'默認分類',
    CategoryName:'分類名稱',
    AddBtn:'添 加',
    UpdateBtn:'修 改',
    UpdateName:'修改分類名稱',
    DelCategory:'刪除分類',
    ConfirmDel:'是否刪除分類下的所有珍藏',
    mobileFilter:'物料 / 產品過濾器',
  },
  programOptions: [
    {
      type: 1,
      text: "包含項目名稱"
    },
    {
      type: 2,
      text: "包含客戶名稱"
    },
    {
      type: 3,
      text: "提供樣板"
    },
    {
      type: 4,
      text: "提供方案"
    }
  ],
  navData:[
    {
      url:'/promotions',
      text:'精選推介',
      children:[
        {
          url:'/promotions/1',
          text:'梁志天私人珍藏',
        },{
          url:'/promotions/2',
          text:'司徒志明先生藝術作品',
        }
        ,{
          url:'/privateresidence',
          text:'私宅設計專區',
        }
      ]
    },{
      url:'/sdx-brand',
      text:'跨界合作',
      children:[]
    },{
      url:'/strategic',
      text:'戰略合作',
      children:[
        {
          url:"/strategic/brand?type=brands",
          text:'品牌',
        },{
          url:"/strategic/brand?type=distributors",
          text:'代理商',
        }
      ]
    },{
      url:'/approved',
      text:'認可合作',
      children:[
        {
          url:"/approved/brand?type=brands",
          text:'品牌',
        },{
          url:"/approved/brand?type=distributors&loc=CN",
          text:'中國代理商',
        },{
          url:"/approved/brand?type=distributors&loc=HK",
          text:'香港代理商',
        }
      ]
    },{
      url:'/product',
      text:'物料 / 產品',
      children:[
        {
          text:'自家研發產品',
          childnode:[
            {
              url:`/product?hl=2&selectcat=2`,
              catId:2,
              text:'活動家具'
            },{
              url:`/product?hl=2&selectcat=3`,
              catId:3,
              text:'裝飾燈具'
            },{
              url:`/product?hl=2&selectcat=4&selectsubcat=229`,
              text:'活動地毯'
            },{
              url:`/product?hl=2&selectcat=393`,
              text:'藝術裝置'
            },{
              url:'',
              text:'裝飾品'
            }
          ]
        },{
          text:'軟裝產品',
          childnode:[
            {
              url:`/product?selectcat=2`,
              text:'活動家具'
            },{
              url:`/product?selectcat=3`,
              text:'裝飾燈具'
            },
            {
              url:'',
              text:'窗簾'
            },{
              url:`/product?selectcat=4&selectsubcat=229`,
              text:'活動地毯'
            },{
              url:`/product?selectcat=393`,
              text:'藝術裝置'
            },{
              url:`/product?selectcat=5`,
              text:'織物'
            },{
              url:`/product?selectcat=6`,
              text:'裝飾品'
            },{
              url:`/product?selectcat=362`,
              text:'布料, 皮, 人造皮'
            }
          ]
        },{
          text:'硬裝產品',
          childnode:[
            {
              url:`/product?selectcat=319`,
              text:'石材'
            },{
              url:`/product?selectcat=315`,
              text:'磚及馬賽克'
            },{
              url:`/product?selectcat=304`,
              text:'地板'
            },{
              url:`/product?selectcat=4`,
              text:'地毯'
            },{
              url:`/product?selectcat=298`,
              text:'護牆板'
            },{
              url:`/product?selectcat=151`,
              text:'牆紙'
            },{
              url:`/product?selectcat=384`,
              text:'特色面料'
            },{
              url:`/product?selectcat=367`,
              text:'油漆'
            },{
              url:`/product?selectcat=322`,
              text:'門'
            },{
              url:`/product?selectcat=73`,
              text:'五金'
            },{
              url:`/product?selectcat=355`,
              text:'玻璃及鏡'
            },{
              url:`/product?selectcat=362`,
              text:'布料, 皮, 人造皮'
            },{
              url:`/product?selectcat=161`,
              text:'木飾面'
            },{
              url:`/product?selectcat=158`,
              text:'金屬飾面'
            },{
              url:`/product?selectcat=359`,
              text:'耐火板'
            },{
              url:`/product?selectcat=376`,
              text:'固定家具'
            },{
              url:`/product?selectcat=68`,
              text:'插座及開關'
            },{
              url:`/product?selectcat=69`,
              text:'建築燈具'
            },{
              url:`/product?selectcat=342`,
              text:'窗簾系統'
            },{
              url:'',
              text:'窗簾'
            },{
              url:`/product?selectcat=299`,
              text:'醫養產品'
            }
          ]
        },{
          text:'衛浴產品',
          childnode:[
            {
              url:`/product?selectcat=311&selectsubcat=66`,
              text:'衛浴潔具'
            },{
              url:`/product?selectcat=311&selectsubcat=72`,
              text:'衛浴龍頭'
            },{
              url:`/product?selectcat=311&selectsubcat=56`,
              text:'衛浴配件'
            },{
              url:`/product?selectcat=311&selectsubcat=324`,
              text:'淋浴房'
            },{
              url:`/product?selectcat=311&selectsubcat=402`,
              text:'衛生間隔斷'
            }
          ]
        },{
          text:'廚房產品',
          childnode:[
            {
              url:`/product?selectcat=306&selectsubcat=307`,
              text:'廚房水槽'
            },{
              url:`/product?selectcat=306&selectsubcat=308`,
              text:'廚房龍頭'
            },{
              url:`/product?selectcat=306&selectsubcat=309`,
              text:'廚房配件'
            },{
              url:`/product?selectcat=306&selectsubcat=310`,
              text:'廚櫃'
            }
          ]
        },{
          text:'電器 / 設備',
          childnode:[
            {
              url:'/product?selectcat=263',
              text:'智能家居系統'
            },{
              url:'/product?selectcat=306&selectsubcat=316',
              text:'廚房電器'
            },{
              url:'/product?selectcat=329',
              text:'洗衣間電器'
            },{
              url:'/product?selectcat=285',
              text:'健身設備'
            },{
              url:'/product?selectcat=283',
              text:'保安設備'
            },{
              url:'/product?selectcat=325',
              text:'火爐'
            },{
              url:'/product?selectcat=369',
              text:'家庭影院系统'
            },{
              url:'/product?selectcat=295',
              text:'專業供應商系統'
            }
          ]
        }
      ]
    },{
      url:'/specialist',
      text:'供應商',
      children:[
        {
          text:'軟裝供應商',
          childnode:[
            {
              url:`/supplier?id=1&name=軟裝供應商`,
              text:'成品供應商'
            },{
              url:'/supplier?id=2&name=軟裝供應商',
              text:'定制品供應商'
            },{
              url:'/supplier-cats?catId=3&name=軟裝供應商',
              text:'第三方服務供應商'
            }]
        },{
          text:'專業製作公司',
          childnode:[
            {
              url:'/supplier-companylist?cl=3&ct=14',
              text:'效果圖'
            },{
              url:'/supplier-companylist?cl=3&ct=15',
              text:'擴初招標圖 / 施工深化圖'
            },{
              url:'/supplier-companylist?cl=3&ct=16',
              text:'施工圖'
            },{
              url:'/supplier-companylist?cl=3&ct=17',
              text:'二次機電圖'
            },{
              url:'/supplier-companylist?cl=3&ct=18',
              text:'VR/AR'
            },{
              url:'/supplier-companylist?cl=3&ct=19',
              text:'BIM'
            },{
              url:'/supplier-companylist?cl=3&ct=20',
              text:'二級攝影'
            },{
              url:'/supplier-companylist?cl=3&ct=37',
              text:'專業攝影'
            },{
              url:'/supplier-companylist?cl=3&ct=36',
              text:'圖文印刷'
            }
          ]
        },{
          text:'專業顧問',
          childnode:[
            {
              url:'/supplier-companylist?cl=2&ct=42',
              text:'建築'
            },{
              url:'',
              text:'結構'
            },{
              url:'/supplier-companylist?cl=2&ct=2',
              text:'燈光'
            },{
              url:'/supplier-companylist?cl=2&ct=52',
              text:'二級燈光'
            },{
              url:'/supplier-companylist?cl=2&ct=3',
              text:'機電'
            },{
              url:'/supplier-companylist?cl=2&ct=5',
              text:'工料測量'
            },{
              url:'/supplier-companylist?cl=2&ct=4',
              text:'園林景觀'
            },{
              url:'/supplier-companylist?cl=2&ct=6',
              text:'標識'
            },{
              url:'/supplier-companylist?cl=2&ct=10',
              text:'聲學'
            },{
              url:'',
              text:'燃氣系統'
            },{
              url:'/supplier-companylist?cl=2&ct=7',
              text:'可持續性發展諮詢'
            },{
              url:'/supplier-companylist?cl=2&ct=8',
              text:'安保'
            },{
              url:'/supplier-companylist?cl=2&ct=44',
              text:'資訊科技及影音'
            },{
              url:'/supplier-companylist?cl=2&ct=45',
              text:'停車場規劃'
            },{
              url:'/supplier-companylist?cl=2&ct=49',
              text:'VR/AR 互動體驗'
            }
          ]
        },{
          text:'專業設備 / 系統供應商',
          childnode:[
            {
              url:'',
              text:'安保系統'
            },{
              url:'/supplier-companylist?cl=1&ct=22',
              text:'智能家居系統'
            },{
              url:'/supplier-companylist?cl=1&ct=23',
              text:'裝甲門'
            },{
              url:'',
              text:'泳池過濾系統'
            },{
              url:'',
              text:'水療設備'
            },{
              url:'/supplier-companylist?cl=1&ct=24',
              text:'酒窖設備'
            },{
              url:'/supplier-companylist?cl=1&ct=25',
              text:'淨水系統'
            },{
              url:'/supplier-companylist?cl=1&ct=26',
              text:'影音系統'
            },{
              url:'',
              text:'無線網絡系統'
            },{
              url:'/supplier-companylist?cl=1&ct=27',
              text:'空氣淨化系統'
            },{
              url:'/supplier-companylist?cl=1&ct=28',
              text:'專業廚房設備'
            },{
              url:'/supplier-companylist?cl=1&ct=35',
              text:'健身器材'
            },{
              url:'/supplier-companylist?cl=1&ct=29',
              text:'健康設計系統'
            },{
              url:'/supplier-companylist?cl=1&ct=31',
              text:'地板採暖系統'
            },{
              url:'/supplier-companylist?cl=1&ct=46',
              text:'數字化工作空間解決方案'
            },{
              url:'/supplier-companylist?cl=1&ct=47',
              text:'智能酒店系統'
            },{
              url:'/supplier-companylist?cl=1&ct=48',
              text:'安全屋'
            },{
              url:'/supplier-companylist?cl=1&ct=51',
              text:'展覽系統'
            }
          ]
        },{
          text:'施工單位',
          childnode:[
            {
              url:'/supplier-companylist?cl=5&ct=43',
              text:'施工單位'
            },
          ]
        }
      ]
    },{
      url:'/guideline?mid=3',
      text:'項目分享',
      children:[
        {
          url:'/deliverable',
          text:'設計成果'
        },{
          url:'/guideline?mid=20',
          text:'項目照片'
        },{
          url:'/design-guideline?mid=8',
          text:'設計指引'
        }
      ]
    },{
      url:'/design-guideline?mid=1',
      text:'設計風格',
      children:[
        {
          url:'/project-file?mid=1&id=801',
          text:'最新設計風格',
          role_needed: '66'
        },{
          url:'/project-file?mid=1&id=1037',
          text:'2023 設計風格'
        },{
          url:'/project-file?mid=1&id=1015',
          text:'2022 設計風格'
        },{
          url:'/project-file?mid=1&id=757',
          text:'2021 設計風格'
        },{
          url:'/project-file?mid=1&id=4',
          text:'2020 設計風格'
        },{
          url:'/project-file?mid=1&id=69',
          text:'2019 設計風格'
        },{
          url:'/project-file?mid=1&id=5',
          text:'2018 設計風格'
        },{
          url:'/project-file?mid=1&id=3',
          text:'2017 設計風格'
        },{
          url:'/project-file?mid=1&id=2',
          text:'2016 設計風格'
        },{
          url:'/project-file?mid=1&id=1',
          text:'2015 設計風格'
        }
      ]
    },{
      url:'/design-guideline?mid=20',
      text:'項目標准文件',
      children:[
        {
          url:'/project-file?mid=20&id=977',
          text:'SL2.0 設計說明格式'
        },{
          url:'/project-file?mid=20&id=930',
          text:'SL2.0 匯報文檔格式'
        },{
          url:'/project-file?mid=20&id=727',
          text:'SLL 清單格式'
        },{
          url:'/project-file?mid=20&id=929',
          text:'SLL 匯報文檔格式'
        },{
          url:'/project-file?mid=20&id=709',
          text:'SLL 設計說明格式'
        },{
          url:'/project-file?mid=20&id=708',
          text:'物料 / 產品應用表格式'
        },{
          url:'/project-file?mid=20&id=62',
          text:'物料樣板格式'
        },{
          url:'/project-file?mid=20&id=61',
          text:'出圖記錄格式'
        },{
          url:'/project-file?mid=20&id=60',
          text:'圖紙目錄格式'
        },{
          url:'/project-file?mid=20&id=59',
          text:'技術說明'
        },{
          url:'/project-file?mid=20&id=58',
          text:'SLD 設計說明格式'
        },{
          url:'/project-file?mid=20&id=57',
          text:'繪圖指引'
        },{
          url:'/project-file?mid=20&id=56',
          text:'SLD 匯報文檔格式'
        }
      ]
    },{
      url:'/guideline?mid=6',
      text:'學習資源',
      children:[
        {
          url:'/design-guideline?mid=30',
          text:'SLD Talk'
        },{
          url:'/design-guideline?mid=31',
          text:'品牌產品介紹視頻'
        },{
          url:'/design-guideline?mid=6',
          text:'設計參考'
        },{
          url:'/design-guideline?mid=33',
          text:'展覽分享'
        },{
          url:'/design-guideline?mid=3',
          text:'技術参考'
        },{
          url:'/design-guideline?mid=7',
          text:'公開演講'
        },{
          url:'/design-guideline?mid=22',
          text:'健康設計'
        },{
          url:'/project-file?mid=35&id=66',
          text:'設計考慮'
        },{
          url:'/design-guideline?mid=23',
          text:'物料 / 產品指引'
        },{
          url:'/design-guideline?mid=36',
          text:'精裝交樓標準設計'
        },{
          url:'/design-guideline?mid=37',
          text:'匯報技巧分享會'
        },{
          url:'/design-guideline?mid=39',
          text:'營銷培訓分享會'
        },{
          url:'/design-guideline?mid=40',
          text:'內部培訓分享會'
        },{
          url:'/design-guideline?mid=41',
          text:'SLD 年度大獎頒獎典禮'
        },{
          url:'/design-guideline?mid=42',
          text:'迎新資料'
        },{
          url:'/design-guideline?mid=43',
          text:'中央管理制度會議'
        },{
          url:'/design-guideline?mid=45',
          text:'場景展示方案'
        },{
        //   url:'/design-guideline?mid=46',
        //   text:'雜誌'
        // },{
          url:'/design-guideline?mid=47',
          text:'藝術品分享'
        }
      ]
    }
  ],
  supplierfilter:{
    supplietype1:'軟裝供應商',
    supplietype2:'專業製作公司',
    supplietype3:'專業顧問',
    supplietype4:'專業設備 / 系統供應商',
    supplietype5:'施工單位',
    Suppliers:'供應商',
    product_brand:'經營品牌',
    nodata:'沒有結果',
    pricerenders:'效果圖製作公司費用總覽',
    pricerendersPRC:'2024 效果圖單價總覽 (國內)',
    pricerendersThai:'2024 效果圖單價總覽 (泰國)',
    reviewsrenders:'效果圖製作公司服務質量總覽',
    designdraffers:'擴初圖製作公司費用總覽',
    reviewdesigndraffers:'擴初圖製作公司服務質量總覽',
    rating:'位用戶評分',
    userreview:'用戶評分',
    score:'評分',
    year:'年份',
    min:'最低',
    max:'平均',
    isnew:'最新',
    contact_lang:'聯絡語言',
    company_size:'規模(員工人數)',
    price_rate:'取費標準',
    company_intro:'公司簡介',
    recommend_by:'推薦人',
    ware_date:'入庫日期',
    sld_project:'最近參與的20個SLDG項目',
    num_project_pre:'最近參與的',
    num_project_beh:'個SLDG項目',
    writeBtn:'撰寫評論',
    rating_target:'評分對象:',
    rating_target_type:'評分對像類型',
    supplier_rating:'供應商評分',
    rating_project:'項目',
    rating_rate:'評分',
    rating_review:'評論',
    optional:'(可選填)',
    upload_attartch:'上傳附件',
    submit:'提交',
    related_document:'相關文檔',
    please_select: '請選擇',
    upload_drag: "拖放文件在這裏上傳",
    upload_width: "闊度下限",
    upload_height: "長度下限",
    upload_limit: "僅支持JPG和PNG圖像！",
    upload_size:"上傳的圖片大小不能超過2MB！",
  },
  Promotions:{
    promotions: "精選推介",
    steve: "梁志天私人珍藏",
    marco: "司徒志明先生藝術作品",
    type_private_house: "私宅設計專區	",
    production_companies: "專業製作公司",
    equip_sys_suppliers: "專業設備 / 系統供應商",
  },
  sdxBrand:{
    san_products: "衛浴產品",
    contact_vendor: "聯繫廠家 / 供應商",
    download_product_cat: "下載產品目錄",
    contact_vendors: "联系供應商",
    store_visiting:'預約實體店',
    direct_distributor:'直營/經銷商',
    furniture: "活動家具",
    lighting: "裝飾燈具",
    see_all: "查看全部",
    discontinued: "已停產",
  },
  Strateg:{
    strategic: "戰略合作",
    brands: "品牌",
    distributors: "代理商",
    product_cat: [
      {
        name:"按產品分類",
        id:null
      }
    ],

    header_company_website: "公司網頁",
    ta_erp: "創意家居 ERP 鏈接",
    ta_erp_doc: "ERP 系統操作指引",
    header_online_store: "網上商店",
    header_product_catalogue: "產品目錄",
    projectType1: [
      {
        type: 1,
        text: "包含項目名稱"
      },
      {
        type: 2,
        text: "包含客戶名稱"
      }

    ],
    projectType2: [
      {
        type: 3,
        text: "提供樣板"
      },
      {
        type: 4,
        text: "提供方案"
      }
    ],
    contact_preview: "預覽",
    approved_china_distributors: "認可合作中國代理商",
    approved_hk_distributors: "認可合作香港代理商",
    strategic_distributors: "戰略合作代理商",
    strategic_brands:"戰略合作品牌",
  },
  approved:{
    approved: "認可合作",
    brands: "品牌",
    china_distributors: "中國代理商",
    hk_distributors: "香港代理商",
    approved_brands: "認可合作品牌",
  },
  welcome:{
    project_sharing: "項目分享",
    design_sharing:'設計分享',
    speech_guidance: "演講 / 指導",
    learning_resources: "學習資源",
    project_photos: "項目照片",
    news: "新發佈",
    sortby: "排序方式",
    sort_desc_update: "按更新時間降序",
    sort_asc_update: "按更新時間升序",
    added_success: "成功！",
    added_fail: "失敗！",
    added_to_cart: "已新增產品。",
    fail_to_add_product: "不允許新增產品。",
    send_email:'正在發送郵件',
    send_email_fail:'發送郵件失敗!',
    design_deliverable: "設計成果",
    design_guidelines: "設計指引",
    project_code: "項目編號",
    project_location: "項目地點",
    project_client: "客方名稱",
    project_cost_fitting_out: "硬裝造價",
    project_cost_ffne: "軟裝造價",
    project_rmb_per_sqm: "平方米",
    project_scope: "經營範圍",
    project_type: "項目類型",
    project_photo_shooting_pro: "專業攝影",
    project_photo_shooting_second: "二級攝影",
    project_photo_shooting_internal: "內部攝影",
    guildline: "設計指引",
    design_style:"設計風格",
    project_grade_general: "一般項目",
    project_grade_important: "重點項目 (#)",
    project_grade_quality: "優質項目 (*)",
    project_grade_premium: "特選項目 (**)",
    project_grade: "項目級別",
    project_contractor: "施工單位",
    project_design_scope: "設計範圍",
    project_design_area: "設計面積",
    project_requirements: "設計要求",
    project_team_construct: "建築團隊",
    project_team_interior: "室內設計團隊",
    project_team_four_cat: "四大件設計團隊",
    project_team_ffne: "飾品設計團隊",
    project_team_implement: "軟裝實施團隊",
    project_budget_construct: "建築成本預算",
    project_cost_construct: "建築造價",
    project_budget_fitting_out: "硬裝成本預算",
    project_equip_excluded: "不含設備",
    project_budget_ffne: "軟裝成本預算",
    project_new_style: "新設計風格的應用",
    project_photo_shooting: "項目攝影",

    file_preview_download: "檔案預覽/下載",
    file_no_external: "只限公司內部網絡可見",
    select_all: "全選",
    download: "下載",
    album_statement: "此文件夾中的照片只能作為參考用途，切勿抄襲或在匯報使用",
    related_url: "相關產品/資訊鏈接",
    view_count: "觀看次數",
    pdf_error:'網絡異常，文件獲取失敗'
  },
  designresinfo:{
    speaker: "演講者",
    place: "地點",
    date: "時間",
    event_name: "活動名",
    event_org: "主辦單位",
    theme: "演講主題",
    people: "其他嘉賓",
    audience: "主要受眾",
    brief: "內容概要",
    standard_project_doc: "項目標准文件",
    rate: "評分",
    no_record: "沒有記錄",
    whoview: "觀看者名單",
    viewname: "觀看者名字",
    viewcount: "觀看次數",
    lastview: "最後一次觀看",
    confirm: "確認",
  }

}
