<template>
  <div class="dislogContent posiR" v-loading="loading">
    <b-container fluid v-if="detailData">
      <b-breadcrumb class="pdt12 ft11 pdl3">
        <b-breadcrumb-item>{{ detailData.cat.name }}</b-breadcrumb-item>
        <b-breadcrumb-item>{{ detailData.subCat.name }}</b-breadcrumb-item>
      </b-breadcrumb>
      <template v-if="detailData.product.input_mode == 0">
        <b-row class="pdp_brief">
          <div class="pdp_thumbnail">
            <ul id="pdp_thumbnail">
              <li
                v-for="(item, index) in itemPhotos"
                :key="index"
                @click="changeSlide(index)"
              >
                <img :src="url + item.path" />
              </li>
            </ul>
          </div>
          <div class="item-photo">
            <swiper :options="swiperOption" ref="mySwiper">
              <swiper-slide
                class="slide"
                v-for="(item, index) in itemPhotos"
                :key="index"
              >
                <div class="swiper-zoom-container">
                  <img :src="url + item.path" />
                </div>
              </swiper-slide>
            </swiper>
            <div class="swiper-pagination-product paginations" slot="pagination"></div>
          </div>
          <div class="pdp_details">
            <detail-info
              :type="0"
              :price-loading="priceLoading"
              :inputmode="detailData.product.input_mode"
              :detail-data="detailData"
              :subopt="subopt"
              @submitData="getsubmitData"
              @change-color="changecolorEvent"
              @showConfirmDialog="showConfirmDialog"
              @showsend1Dialog="showsend1Dialog"
              @upload-dialog="showuploadDialog"
              @changeMaterial="changeMaterial"
              @checkBoxEvent="checkBoxEvent"
            ></detail-info>
          </div>
        </b-row>
        <b-row>
          <div
            v-if="
              subopt.selectedSubOptSizeStr ||
              detailData.product[detailData.descName] ||
              detailData.product[detailData.subOptDescName] ||
              detailData.product[detailData.remarkName]
            "
            class="pdp_description"
          >
            <div class="sep_line"></div>
            <h4
              v-if="
                subopt.selectedSubOptSizeStr ||
                detailData.product[detailData.descName] ||
                detailData.product[detailData.subOptDescName]
              "
            >
              {{ $t("productfilter.description") }}
            </h4>
            <div class="mgt30">
              <!-- {{subopt.selectedSubOptSizeStr}} -->
              <span
                v-if="subopt.selectedSubOptSizeStr"
                v-html="subopt.selectedSubOptSizeStr"
              ></span>
              <br />
              <p
                v-if="detailData.product[detailData.descName]"
                v-html="
                  detailData.product[detailData.descName].replace(
                    /\r\n/g,
                    '<br/>'
                  )
                "
              ></p>
              <p>
                {{ detailData.product[detailData.subOptDescName] }}
              </p>
              <br />
            </div>
            <template
              class="mgt30"
              v-if="detailData.product[detailData.remarkName]"
            >
              <h4>{{ $t("productfilter.remark") }}</h4>
              <div
                class="info-container"
                v-html="
                  detailData.product[detailData.remarkName].replace(
                    /\r\n/g,
                    '<br/>'
                  )
                "
              ></div>
            </template>
            <div class="cert-icons mgt50" v-if="detailData.certInfo.length > 0">
              <ul>
                <li
                  v-for="(item, index) in detailData.certInfo"
                  :key="index"
                  @click="showCertInfo(item)"
                >
                  <img :src="url + item.path" v-if="item.path" />
                  <em v-else> {{ item.name_tc }}</em>
                </li>
              </ul>
            </div>
            <div
              id="contentImage"
              class="text-center"
              style="padding-top: 40px"
            >
              <img
                :src="url + item.path"
                style="width: auto; max-width: 100%"
                v-for="(item, index) in detailData.contentPhotos"
                :key="index"
              />
            </div>
          </div>
        </b-row>
      </template>

      <!-- PDF Viewer -->
      <template v-else>
        <b-row class="pdp_brief catalog_view">
          <div sm="1" offset-lg="1" lg="1" class="pdp_thumbnail">
            <ul id="pdp_thumbnail">
              <li
                v-for="(item, index) in itemPhotos"
                :key="index"
                @click="changeSlide(index)"
              >
                <img :src="url + item.path" />
              </li>
            </ul>
          </div>
          <div xs="12" sm="6" lg="6" class="catalog">
            <div class="catalog_preview" style="flex: auto; position: relative">
              <div class="iframe-div" v-if="detailData.product.file_catalog">
                <PDFViewer
                  data-root="product-detail"
                  :data-name="detailData.product.name"
                  :data-str="url + detailData.product.file_catalog"
                  :data-id="detailData.product.id"
                />
              </div>
            </div>
          </div>

          <div class="catalog_details" xs="12" sm="5" lg="3">
            <detail-info
              :type="0"
              :inputmode="detailData.product.input_mode"
              :price-loading="priceLoading"
              :detail-data="detailData"
              :subopt="subopt"
              @submitData="getsubmitData"
              @change-color="changecolorEvent"
              @showConfirmDialog="showConfirmDialog"
              @showsend1Dialog="showsend1Dialog"
              @upload-dialog="showuploadDialog"
              @changeMaterial="changeMaterial"
              @checkBoxEvent="checkBoxEvent"
            ></detail-info>
          </div>
        </b-row>
      </template>
    </b-container>

    <Upload
      ref="upload"
      :upload-dialog="uploadDialog"
      @uploadChange="closeUploadChange"
    ></Upload>
    <confirm-message
      :detail-data="detailData"
      :is-visble="sendDialog"
      v-if="detailData != ''"
    ></confirm-message>
    <send-message
      :detail-data="detailData"
      :submit-data="submitData"
      :is-visble="send1Dialog"
      v-if="detailData != ''"
      @dialogChange="closedialogChange"
    ></send-message>
  </div>
</template>

<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { formatDate } from "@/utils/date";
import { getProductDetial, getProductsubopt } from "@/api/product";
import productDetailInfo from "@/components/ProductDetailInfo";
import Upload from "@/components/Upload";
import confirmMessage from "@/components/ConfirmMessage";
import sendMessage from "@/components/SendMessage";
import PDFViewer from '@/components/PDFViewer';

export default {
  components: {
    swiper,
    "swiper-slide": swiperSlide,
    "detail-info": productDetailInfo,
    Upload,
    "confirm-message": confirmMessage,
    "send-message": sendMessage,
    PDFViewer
  },
  data() {
    return {
      priceLoading: false,
      activeNames: ["1"],
      swiperOption: {
        loop: false,
        autoplay: false,
        pagination: {
          el: ".swiper-pagination-product",
          clickable: true,
        },
        zoom: {
          maxRatio: 3,
        },
      },
      loading: false,
      dialogVisible: false,
      detailData: "",
      url: process.env.VUE_APP_IMG_URL,
      sendDialog: true,
      send1Dialog: false,
      submitData: {
        venID: 1,
        project: {
          id: "",
          project_no: "",
          versionwithzero: "",
          project_type: "",
          name: "",
          client: "",
          currency_id: ""
        },
        incPName: 0,
        incCName: 0,
        catName: "",
        brandName: "",
        seriesName: "",
        productsToAdd: [],
        msg: "",
        mobileNo: "",
        wechatNo: "",
        requestSample: 0,
        requestProposal: 0,
        deadline: "",
        orderId: ""
      },

      uploadDialog: false,
      selectColor: 0,
      // wishStatus: false,
      itemPhotos: [],
      subopt: {},
      forRegion:'',
    };
  },
  filters: {
    formatCreateTime(time) {
      let date = new Date(time);
      return formatDate(date, "yyyy/MM/dd");
    }
  },
  created() {
    let id = this.$route.query.id,
      optID = this.$route.query.optID,
      subOptID = this.$route.query.subOptID || null;
    // hl = this.$route.query.hl;
    this.forRegion = window.localStorage.getItem("forRegion");
    this.getDetail(id, optID, subOptID, this.forRegion);
  },
  methods: {
    getDetail(id, optID, subOptID, forRegion) {
      this.loading = true;
      const data = {
        optID: optID,
        subOptID: subOptID,
        forRegion: forRegion
      };
      getProductDetial(id, data).then((res) => {
        this.loading = false;
        let datas = res.data;
        this.detailData = datas;
        this.itemPhotos = datas.itemPhotos;
        this.submitData.catName = this.detailData.cat.name;
        this.submitData.brandName = this.detailData.productInfo.brandInfo.name;
        this.submitData.seriesName =
          this.detailData.productInfo.brandInfo.seriesName;
        let arr = [];
        for (var i in datas.cooperateList) {
          let o = {};
          (o["value"] = i), (o["text"] = datas.cooperateList[i]);
          arr.push(o);
        }
        this.cooperateList = arr;
        this.getsubopt(optID);
      });
    },
    getsubopt(id, color_code) {
      this.priceLoading = true;
      const data = {
        forRegion: this.forRegion
      };
      getProductsubopt(id,data).then((res) => {
        this.subopt = res.data;
        if (this.subopt.price == "HKD$ 0") {
          this.subopt.price = this.$t("productfilter.product_tbc");
        }
        this.priceLoading = false;
        if (color_code) {
          let imgurl = [];
          let obj = {};
          obj.path = this.subopt.selectedSubOptImg;
          imgurl.push(obj);
          this.itemPhotos = imgurl;
        }
      });
    },

    changeSlide(index) {
      this.swiper.slideTo(index);
    },

    getsubmitData(data) {
      this.submitData = data;
    },
    changecolorEvent(data, item) {
      if (item.pattern_img) {
        this.itemPhotos = data;
      }
      this.getsubopt(item.id, item.color_code);
    },
    changeMaterial(item) {
      this.getsubopt(item.id);
    },
    checkBoxEvent(val) {
      this.subopt.selectedSubOptSizeStr = val.subOptSizeStr;
    },
    closeUploadChange(status) {
      this.uploadDialog = status;
    },
    closedialogChange(status) {
      this.send1Dialog = status;
    },
    changColor(item) {
      let imgurl = [];
      let obj = {};
      obj.path = item.pattern_img;
      imgurl.push(obj);
    },
    showsend1Dialog(data) {
      this.send1Dialog = data;
    },
    showuploadDialog(boolen) {
      this.uploadDialog = boolen;
    },
    showConfirmDialog(data) {
      this.sendDialog = data;
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    }
  }
};
</script>

<style lang="scss" scoped>
.dislogContent {
  .breadcrumb {
    padding-left: 7.5px;
    padding-right: 7.5px;
    margin-bottom: 40px;
    @media (max-width: 1023px) {
      margin-bottom: 20px;
    }
  }
  .pdp_brief {
    max-width: 1530px;
    padding: 0 7.5px;
    margin: 0 auto;
    .pdp_thumbnail {
      display: block;
      padding: 0;
      max-width: 100px;
      width: 6%;
      @media (max-width: 1023px) {
        display: none;
      }
      ul{
        li {
          margin-bottom: 0.5em;
          text-align: center;
          img {
            width: 100%;
            vertical-align: middle;
            border: 0;
            object-fit: contain;
            aspect-ratio: 1/1;
          }
        }
      }
    }
    .item-photo {
      margin-bottom: 50px !important;
      padding: 0px 5%;
      min-height: 0;
      width: 60%;
      @media (max-width: 1023px) {
        padding: 0;
        width: 100%;
      }
      .swiper-zoom-container {
        aspect-ratio: 4/3;
        @media (max-width: 1023px) {
          aspect-ratio: unset;
        }
        img {
          max-width: 100%;
          margin: 0 auto auto;
          width: 100%;
          /*max-height: 70vh;*/
          @media (max-width: 1023px) {
            aspect-ratio: 1/1;
          }
        }
      }
    }
    .pdp_details {
      width: 34%;
      padding: 0px;
      @media (max-width: 1023px) {
        width: 100%;
        padding: 0;
      }
    }

    &.catalog_view {
      max-width: none;
      .pdp_thumbnail {
        @media (max-width: 1023px) {
          display: block;
          width: 100%;
          max-width: none;
          margin-bottom: 20px;
          order: 2;
          ul {
            display: flex;
            li {
              max-width: 50px;
            }
          }
        }
      }
      .catalog {
        margin-bottom: 50px;
        padding: 0px 2%;
        min-height: calc(100vh - 190px);
        width: calc(94% - 400px);
        order: 1;
        @media (max-width: 1023px) {
          margin-top: -8px;
          margin-bottom: 0px;
          padding: 0px;
          width: 100%;
        }
        .catalog_preview {
          width: auto;
          flex: auto;
          position: relative;
          height: 100%;
          .iframe-div {
            height: 100%;
            div {
              height: 100%;
            }
          }
          iframe {
            width: 100%;
            height: 77.5vh;
            height: 70vh;
            border: 5px solid #e5e5e5;
            @media (max-width: 1023px) {
              height: 65vh;
              min-height: 420px;
              border: 1px solid #e7e7e7;
            }
          }
        }
      }
      .catalog_details {
        float: right;
        width: 400px;
        order: 3;
        @media (max-width: 1023px) {
          width: 100%;
          margin-bottom: 30px;
        }
      }
    }
  }
  .hidden-xs {
    @media (max-width: 767px) {
      display: none !important;
    }
  }

  .pdp_description {
    max-width: 1530px;
    margin: 50px auto 0;
    padding: 0 15px 50px;

    .sep_line {
      height: 1px;
      width: 100%;
      border-top: #ddd 1px solid;
      margin-bottom: 40px;
    }
  }
}
.detail-dialog /deep/ .el-dialog__header {
  padding-top: 0;
}
.detail-dialog /deep/ .el-dialog__headerbtn {
  right: 10px;
  top: 10px;
}
.detail-dialog /deep/ .el-dialog__body {
  // max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 20px;
  padding: 0 0 20px;
  // height: 400px;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}
/deep/.el-loading-mask {
  position: fixed;
}
.detail-dialog /deep/.el-loading-mask {
  position: absolute;
}
/deep/ .el-collapse-item__content {
  padding-bottom: 0;
}


.send-dialog .cat_preview {
  h6 {
    margin-top: 20px;
    font-weight: bold;
    font-size: 14px;
  }
  > div {
    margin-top: 5px;
    line-height: 180%;
    label {
      width: 100px;
    }
  }
}
.dialog-footer {
  button {
    background: #ecf0f1;
    color: #000;
    text-shadow: none;
    -webkit-transition: background 0.2s;
    transition: background 0.2s;
  }
}
.dialog /deep/ .el-dialog__footer {
  border-top: #ddd 1px solid;
}

/deep/ .el-dialog__close {
  font-size: 20px;
  font-weight: bold;
}

.paginations {
  position: static;
  margin-top: 20px;
  display: none;
  span {
    cursor: pointer;
  }
  @media (max-width: 1023px) {
    /*margin-top: 5px;*/
    display: flex;
    justify-content: center;
  }
}
/deep/ .swiper-pagination-bullet {
  width: 50px;
  border-radius: 0;
  opacity: 1;
  margin: 0 2px;
  padding: 15px 0;
  background: none;
  @media (max-width: 1024px) {
    width: 40px;
  }
  @media (max-width: 767px) {
    max-width: 30px;
  }
  &::before {
    display: block;
    width: 100%;
    height: 2px;
    background: #d6d6d6;
    content: " ";
  }
  &:hover::before {
    background: #869791;
  }
}
/deep/ .swiper-pagination-bullet-active {
  background: none;
  &::before {
    background: #869791;
  }
}
</style>
